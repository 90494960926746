import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class Sidebar extends Component {
    
    state = {
        userData: null
    };

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'))
        this.setState({userData})
    }

    render() {
        return (
            <div id="wrapper">
                <ul className="sidebar navbar-nav">
                    <li className="nav-item active">
                        <Link to={'/dashboard'} className="nav-link"><i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>&nbsp;Dashboard</span></Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/vaults'} className="nav-link"><i className="fas fa-fw fa-table"></i>
                            <span>&nbsp;Vaults</span></Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/supervaults'} className="nav-link"><i className="fas fa-fw fa-table"></i>
                            <span>&nbsp;Supervaults</span></Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/trades-history'} className="nav-link"><i className="fas fa-fw fa-table"></i>
                            <span>&nbsp;Trades History</span></Link>
                    </li>
                    {this.state.userData?.isAdmin && (
                    <li className="nav-item">
                        <Link to={'/users'} className="nav-link"><i className="fas fa-fw fa-user"></i>
                            <span>&nbsp;Users</span></Link>
                    </li>
                    )}
                </ul>
            </div>
        );
    }
}
