import React, { Component } from 'react';
import Header from "../elements/header";
import Sidebar from "../elements/sidebar";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';

import { Config } from '../configs/env';

export default class Dashboard extends Component {

    state = {
        vaultsCount: 0,
        usersCount: 0,
        supervaultsCount: 0,
        redirect: false,
        apiUrl: localStorage.getItem('backend') || Config.apiUrl.dev
    };

    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
    }
    
    componentDidMount() {
        axios.get(`${this.state.apiUrl}/auth`, { headers: { Authorization: `Bearer ${this.token}` } })
            .then(result => {
                if (result.data.id) {
                    localStorage.setItem('userData', JSON.stringify(result.data))
                }
            })
            .catch(error => {
                this.setState({redirect: true});
                localStorage.setItem('isLoggedIn', false);
            });

        axios.get(`${this.state.apiUrl}/vaults/items`, { headers: { Authorization: `Bearer ${this.token}` } })
            .then(response => {
                const vaultsCount = response.data?.length;
                this.setState({ vaultsCount });
            })
            .catch(error => {
                this.setState({ toDashboard: true });
                console.log(error);
            });
            
        axios.get(`${this.state.apiUrl}/users`, { headers: { Authorization: `Bearer ${this.token}` } })
        .then(response => {
            const usersCount = response.data?.length;
            this.setState({ usersCount });
        })
        .catch(error => {
            this.setState({ toDashboard: true });
            console.log(error);
        });
        
        axios.get(`${this.state.apiUrl}/vaults/supervaults`, { headers: { Authorization: `Bearer ${this.token}` } })
            .then(response => {
                const supervaultsCount = response.data?.length;
                this.setState({ supervaultsCount });
            })
            .catch(error => {
                this.setState({ toDashboard: true });
                console.log(error);
            });
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/'/>
        }
    };

    render() {
        return (
            <div>
                <Header/>
                <div id="wrapper">
                    <Sidebar></Sidebar>
                    <div id="content-wrapper">
                        <div className="container-fluid">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/dashboard'} >Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">Overview</li>
                            </ol>

                            <div className="row">
                                <div className="col-xl-3 col-sm-6 mb-3">
                                    <div className="card text-white bg-primary o-hidden h-100">
                                        <div className="card-body">
                                            <div className="card-body-icon">
                                                <i className="fas fa-fw fa-comments"></i>
                                            </div>
                                            <div className="mr-5">{this.state.vaultsCount} Vaults!</div>
                                        </div>
                                        <Link className="card-footer text-white clearfix small z-1" to="/vaults">
                                            <span className="float-left">View Details</span>
                                            <span className="float-right"><i className="fas fa-angle-right"></i></span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6 mb-3">
                                    <div className="card text-white bg-warning o-hidden h-100">
                                        <div className="card-body">
                                            <div className="card-body-icon">
                                                <i className="fas fa-fw fa-list"></i>
                                            </div>
                                            <div className="mr-5">{this.state.supervaultsCount} Supervaults!</div>
                                        </div>
                                        <Link className="card-footer text-white clearfix small z-1" to="/supervaults">
                                            <span className="float-left">View Details</span>
                                            <span className="float-right"><i className="fas fa-angle-right"></i></span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6 mb-3">
                                    <div className="card text-white bg-success o-hidden h-100">
                                        <div className="card-body">
                                            <div className="card-body-icon">
                                                <i className="fas fa-fw fa-shopping-cart"></i>
                                            </div>
                                            <div className="mr-5">{this.state.usersCount} Users!</div>
                                        </div>
                                        <Link className="card-footer text-white clearfix small z-1" to="/users">
                                            <span className="float-left">View Details</span>
                                            <span className="float-right"><i className="fas fa-angle-right"></i></span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6 mb-3">
                                    <div className="card text-white bg-danger o-hidden h-100">
                                        <div className="card-body">
                                            <div className="card-body-icon">
                                                <i className="fas fa-fw fa-life-ring"></i>
                                            </div>
                                            <div className="mr-5">Cooming soon...</div>
                                        </div>
                                        <Link className="card-footer text-white clearfix small z-1" to="#">
                                            <span className="float-left">View Details</span>
                                            <span className="float-right"><i className="fas fa-angle-right"></i></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <footer className="sticky-footer">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright © Your Website 2019</span>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
                
                {this.renderRedirect()}
            </div>

        );
    }
}
