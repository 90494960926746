import React, { Component } from 'react';
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom';
import Header from "../../elements/header";
import Sidebar from "../../elements/sidebar";

import { Config } from '../../configs/env';

export default class EditPage extends Component {

    state = {
        id: '',
        redirect: false,
        isLoading: false,
        isProfile: false,
        apiUrl: localStorage.getItem('backend') || Config.apiUrl.dev
    };

    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
    }

    componentDidMount() {
        const id = this.props.match.params.id
        const search = this.props.location.search
        if (search && search.indexOf('profile') >= 0) {
            this.setState({isProfile: true})
        }
        
        axios.get(`${this.state.apiUrl}/users/${id}` , { headers: { Authorization: `Bearer ${this.token}` } })
            .then(response => {
                const userData = response.data;
                this.setState({id: id, user: userData });
                document.getElementById('inputId').value = userData.id;
                document.getElementById('inputName').value = userData.username;
                document.getElementById('inputFullname').value = userData.fullname;
                document.getElementById('inputActive').checked = userData.isActive;
                document.getElementById('inputAdmin').checked = userData.isAdmin;
            })
            .catch(error => {
                this.setState({ toDashboard: true });
                console.log(error);
            });
        
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        const url = `${this.state.apiUrl}/users/update/${this.state.id}`;
        const userData = {
            username: document.getElementById('inputName').value,
            password: document.getElementById('inputPassword').value,
            fullname: document.getElementById('inputFullname').value,
            isActive: document.getElementById('inputActive').checked,
            isAdmin: document.getElementById('inputAdmin').checked
        }
        axios.post(url, userData , { headers: { Authorization: `Bearer ${this.token}` } })
            .then(result => {
                if (result.data.status) {
                    this.setState({redirect: true, isLoading: false})
                }
            })
            .catch(error => {
                this.setState({ toDashboard: true });
                console.log(error);
            });
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.isProfile ? '/dashboard' : '/users'} />
        }
    };

    render() {
        const isLoading = this.state.isLoading;
        if (this.state.toDashboard === true) {
            return <Redirect to='/' />
        }
        return (
            <div>
                <Header/>
                <div id="wrapper">
                    <Sidebar></Sidebar>
                    <div id="content-wrapper">
                        <div className="container-fluid">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={this.state.isProfile ? '/dashboard' : '/users'} >{this.state.isProfile ? 'Dashboard' : 'Users'}</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit</li>
                            </ol>
                        </div>
                        <div className="container-fluid">
                            <div className="card mx-auto">
                                <div className="card-header">{this.state.user?.name}</div>
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row my-3">
                                                    <div className="form-label-group col-md-6">
                                                        <input type="text" id="inputId" readOnly={true} className="form-control" placeholder="Enter name" required="required" autoFocus="autofocus" />
                                                        <label htmlFor="inputId">User Id</label>
                                                    </div>
                                        </div>
                                        <div className="form-row my-3">
                                                    <div className="form-label-group col-md-6">
                                                        <input type="text" id="inputName" className="form-control" placeholder="Enter name" required="required" autoFocus="autofocus" />
                                                        <label htmlFor="inputName">Email</label>
                                                    </div>
                                        </div>
                                        <div className="form-row my-3">
                                                    <div className="form-label-group col-md-6">
                                                        <input type="text" id="inputFullname" className="form-control" placeholder="Enter name" required="required" autoFocus="autofocus" />
                                                        <label htmlFor="inputFullname">Full Name</label>
                                                    </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="checkbox" id="inputActive" className="form-control" placeholder="Enter Location"/>
                                                <label htmlFor="inputActive">Is Active</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="checkbox" id="inputAdmin" className="form-control" placeholder="Enter Location"/>
                                                <label htmlFor="inputAdmin">Is Superuser</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                                    <div className="form-label-group col-md-6">
                                                        <input type="password" id="inputPassword" className="form-control" placeholder="Enter name" required="required" autoFocus="autofocus" />
                                                        <label htmlFor="inputPassword">Password</label>
                                                    </div>
                                        </div>
                                        <button className="btn btn-primary btn-block col-md-6" type="submit" disabled={this.state.isLoading ? true : false}>Update User &nbsp;&nbsp;&nbsp;
                                            {isLoading ? (
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </button>
                                    </form>
                                    {this.renderRedirect()}
                                </div>
                            </div>
                        </div>

                        <footer className="sticky-footer">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright © Your Website 2019</span>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}


