import React, { Component } from 'react';
import Header from "../../elements/header";
import Sidebar from "../../elements/sidebar";
import {Link, Redirect} from 'react-router-dom';
import axios from 'axios';

import { Config } from '../../configs/env';

export default class Vaults extends Component {
    state = {
        vaults: [],
        toDashboard: false,
        isLoading: false,
        apiUrl: localStorage.getItem('backend') || Config.apiUrl.dev
    };

    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
    }
    
    componentDidMount() {
        axios.get(`${this.state.apiUrl}/vaults/supervaults/items`, { headers: { Authorization: `Bearer ${this.token}` } })
            .then(response => {
                const vaults = response.data;
                this.setState({ vaults });
            })
            .catch(error => {
                this.setState({ toDashboard: true });
                console.log(error);
            });
    }

    handleClickDelete = event => {
        if (!window.confirm('Are you sure?')) return;
        
        const id = event.target.value;
        axios.delete(`${this.state.apiUrl}/vaults/supervaults/delete/${id}` , { headers: { Authorization: `Bearer ${this.token}` } })
            .then(response => {
                this.componentDidMount();
                this.setState({ isLoading: true})
            })
            .catch( error => {
                console.log(error.toString());
                this.setState({ toDashboard: true });
            });
    };

    render() {
        if (this.state.toDashboard === true) {
            return <Redirect to='/' />
        }
        return (
            <div>
                <Header/>
                <div id="wrapper">
                    <Sidebar/>
                    <div id="content-wrapper">
                        <div className="container-fluid">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/dashboard'} >Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">Supervaults</li>
                                <li className="ml-auto"><Link to={'/supervaults/add'}>Add Supervault</Link></li>
                            </ol>
                            <div className="card mb-3">
                                <div className="card-header"><i className="fas fa-table"></i>
                                    &nbsp;&nbsp;Supervault List
                                </div>
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>id</th>
                                            <th>Name</th>
                                            <th>Currency</th>
                                            <th>Perf Fees</th>
                                            <th>Active</th>
                                            <th>Chain</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.vaults.map((vault , index)=>
                                                <tr key={vault.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{vault.name}</td>
                                                    <td>{vault.currency}</td>
                                                    <td>{vault.perfFees}%</td>
                                                    <td><input type={'checkbox'} defaultChecked={vault.isActive}/></td>
                                                    <td>{vault.chain}</td>
                                                    <td className="text-center">
                                                        <Link className="btn btn-sm btn-info" to={{ pathname: '/supervaults/edit/' + vault.id }}>Edit</Link>
                                                        &nbsp; | &nbsp;
                                                        <button value={vault.id} className="btn btn-sm btn-danger" onClick={this.handleClickDelete} >Delete</button>
                                                    </td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <footer className="sticky-footer">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright © Upbots Team 2022</span>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}
