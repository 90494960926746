import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Vaults from "./pages/vaults/vaults";
import VaultAddPage from "./pages/vaults/add";
import VaultEditPage from "./pages/vaults/edit";
import UserAddPage from "./pages/users/add";
import UserEditPage from "./pages/users/edit";
import NotFound from "./pages/notfound";
import Users from './pages/users/users';
import TradesHistory from './pages/trades-history/trades-history';
import TradesHistoryAddPage from "./pages/trades-history/add";
import TradesHistoryEditPage from "./pages/trades-history/edit";
import Supervaults from './pages/supervaults/vaults';
import SupervaultsAddPage from "./pages/supervaults/add";
import SupervaultsEditPage from "./pages/supervaults/edit";

class App extends Component {

    render() {
        return (
            <div className="App">
                <Router>
                    <Switch>
                        <Route exact path='/' component={Login} />
                        <Route exact path='/dashboard' component={Dashboard} />
                        <Route exact path='/vaults' component={Vaults}/>
                        <Route exact path='/supervaults' component={Supervaults} />
                        <Route exact path='/vaults/add' component={VaultAddPage} />
                        <Route exact path='/vaults/edit/:id' component={VaultEditPage} />
                        <Route exact path='/users/' component={Users} />
                        <Route exact path='/users/add' component={UserAddPage} />
                        <Route exact path='/users/edit/:id' component={UserEditPage} />
                        <Route exact path='/trades-history/' component={TradesHistory} />
                        <Route exact path='/trades-history/add' component={TradesHistoryAddPage} />
                        <Route exact path='/trades-history/edit/:id' component={TradesHistoryEditPage} />
                        <Route exact path='/supervaults/' component={Supervaults} />
                        <Route exact path='/supervaults/add' component={SupervaultsAddPage} />
                        <Route exact path='/supervaults/edit/:id' component={SupervaultsEditPage} />
                        <Route path='*' component={NotFound} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;
