import React, { Component } from 'react';
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom';
import Header from "../../elements/header";
import Sidebar from "../../elements/sidebar";

import { Config } from '../../configs/env';

export default class EditPage extends Component {

    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
    }

    state = {
        id: '',
        vault: null,
        redirect: false,
        isLoading: false,
        apiUrl: localStorage.getItem('backend') || Config.apiUrl.dev
    };

    componentDidMount() {
        const id = this.props.match.params.id
        axios.get(`${this.state.apiUrl}/vaults/item/${id}`, { headers: { Authorization: `Bearer ${this.token}` } })
            .then(response => {
                const VaultData = response.data;
                this.setState({id: id, vault: VaultData });
                document.getElementById('inputId').value = VaultData.id;
                document.getElementById('inputName').value = VaultData.name;
                document.getElementById('inputContractAddress').value = VaultData.contractAddress;
                document.getElementById('inputCurrency1').value = VaultData.currency1;
                document.getElementById('inputCurrency2').value = VaultData.currency2;
                document.getElementById('inputPerfFee').value = VaultData.perfFees;
                document.getElementById('inputActive').checked = VaultData.isActive;
                document.getElementById('inputNew').checked = VaultData.isNew;
                document.getElementById('inputVersion').value = VaultData.version;
                document.getElementById('inputDepositFee').value = VaultData.depositFee;
                document.getElementById('inputWithdrawFee').value = VaultData.withdrawalFee;
                document.getElementById('inputSafetyLevel').value = VaultData.safetyLevel;
                document.getElementById('inputDescription').value = VaultData.description;
                document.getElementById('inputStrategy').value = VaultData.strategy;
                document.getElementById('inputRiskLevel').value = VaultData.riskLevel;
                document.getElementById('inputChain').value = VaultData.chain;
                document.getElementById('inputNextVersion').value = VaultData.nextVersion;
                document.getElementById('inputPrevVersion').value = VaultData.prevVersion;
            })
            .catch(error => {
                this.setState({ toDashboard: true });
                console.log(error);
            });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        const url = `${this.state.apiUrl}/vaults/update/${this.state.id}`;
        const vaultData = {
            name: document.getElementById('inputName').value,
            contractAddress: document.getElementById('inputContractAddress').value,
            currency1: document.getElementById('inputCurrency1').value,
            currency2: document.getElementById('inputCurrency2').value,
            perfFees: document.getElementById('inputPerfFee').value,
            isActive: document.getElementById('inputActive').checked,
            isNew: document.getElementById('inputNew').checked,
            version: document.getElementById('inputVersion').value,
            depositFee: document.getElementById('inputDepositFee').value,
            withdrawalFee: document.getElementById('inputWithdrawFee').value,
            safetyLevel: document.getElementById('inputSafetyLevel').value,
            description: document.getElementById('inputDescription').value,
            strategy: document.getElementById('inputStrategy').value,
            riskLevel: document.getElementById('inputRiskLevel').value,
            chain: document.getElementById('inputChain').value,
            nextVersion: document.getElementById('inputNextVersion').value,
            prevVersion: document.getElementById('inputPrevVersion').value,
        }
        axios.post(url, vaultData , { headers: { Authorization: `Bearer ${this.token}` } })
            .then(result => {
                if (result.data.status) {
                    this.setState({redirect: true, isLoading: false})
                }
            })
            .catch(error => {
                this.setState({ toDashboard: true });
                console.log(error);
            });
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/vaults' />
        }
    };

    render() {
        const isLoading = this.state.isLoading;
        if (this.state.toDashboard === true) {
            return <Redirect to='/' />
        }
        return (
            <div>
                <Header/>
                <div id="wrapper">
                    <Sidebar></Sidebar>
                    <div id="content-wrapper">
                        <div className="container-fluid">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/vaults'} >Vaults</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit</li>
                            </ol>
                        </div>
                        <div className="container-fluid">
                            <div className="card mx-auto">
                                <div className="card-header">{this.state.vault?.name}</div>
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputId" readOnly className="form-control" placeholder="Enter name" required="required" autoFocus="autofocus" />
                                                <label htmlFor="inputId">Vault Id</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="col-md-6">
                                                <select className="form-control" defaultValue={"bsc"} id="inputChain">
                                                    <option value="bsc">BSC</option>
                                                    <option value="eth">ETH</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputName" className="form-control" placeholder="Enter name" required="required" autoFocus="autofocus" />
                                                <label htmlFor="inputName">Name</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputContractAddress" className="form-control" placeholder="Enter Phone" required="required" />
                                                <label htmlFor="inputContractAddress">Contract Address</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputCurrency1" className="form-control" placeholder="Email address" required="required" />
                                                <label htmlFor="inputCurrency1">Currency1</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputCurrency2" className="form-control" placeholder="Enter Company" required="required"/>
                                                <label htmlFor="inputCurrency2">Currency2</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="number" step={'any'} id="inputPerfFee" className="form-control" placeholder="Enter Emp ID" required="required" />
                                                <label htmlFor="inputPerfFee">Perf. Fee</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="number" step={'any'} id="inputDepositFee" className="form-control" placeholder="Enter Emp ID" required="required" />
                                                <label htmlFor="inputDepositFee">Deposit Fee</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="number" step={'any'} id="inputWithdrawFee" className="form-control" placeholder="Enter Location" required="required"/>
                                                <label htmlFor="inputWithdrawFee">Withdrawal Fee</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="number" step={'any'} id="inputSafetyLevel" className="form-control" placeholder="Enter Emp ID" required="required" />
                                                <label htmlFor="inputSafetyLevel">Safety Level</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputDescription" className="form-control" placeholder="Enter Location" required="required"/>
                                                <label htmlFor="inputDescription">Description</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputRiskLevel" className="form-control" placeholder="Enter Location" required="required"/>
                                                <label htmlFor="inputRiskLevel">RiskLevel</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputStrategy" className="form-control" placeholder="Enter Location" required="required"/>
                                                <label htmlFor="inputStrategy">Strategy</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputNextVersion" className="form-control" placeholder="Enter Location"/>
                                                <label htmlFor="inputNextVersion">Next Version</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputPrevVersion" className="form-control" placeholder="Enter Location"/>
                                                <label htmlFor="inputPrevVersion">Prev Version</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputVersion" className="form-control" placeholder="Enter Location" required="required"/>
                                                <label htmlFor="inputVersion">Version</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="checkbox" id="inputActive" className="form-control" placeholder="Enter Location"/>
                                                <label htmlFor="inputActive">Is Active</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="checkbox" id="inputNew" className="form-control" placeholder="Enter Emp ID" />
                                                <label htmlFor="inputNew">Is New</label>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary btn-block col-md-6" type="submit" disabled={this.state.isLoading ? true : false}>Update Vault &nbsp;&nbsp;&nbsp;
                                            {isLoading ? (
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </button>
                                    </form>
                                    {this.renderRedirect()}
                                </div>
                            </div>
                        </div>

                        <footer className="sticky-footer">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright © Your Website 2019</span>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}


