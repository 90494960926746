import React, {Component} from 'react';
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom';
import TitleComponent from "./title";

import { Config } from '../configs/env';

export default class Login extends Component {

    state = {
        email: '',
        password: '',
        redirect: false,
        authError: false,
        isLoading: false,
        location: {},
        apiUrl: localStorage.getItem('backend')
    };

    handleEmailChange = event => {
        this.setState({email: event.target.value});
    };

    handlePwdChange = event => {
        this.setState({password: event.target.value});
    };

    handleBackendChange = event => {
        const backend = document.getElementById('inputBackend').value;
        console.log(Config.apiUrl[backend])
        localStorage.setItem('backend', Config.apiUrl[backend]);
        this.setState({apiUrl: Config.apiUrl[backend]});
    };

    handleSubmit = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        let loginData = {
            'username': this.state.email,
            'password': this.state.password
        };
        axios.post(`${this.state.apiUrl}/auth/login`, loginData)
            .then(result => {
                if (result.data.id) {
                    localStorage.setItem('token', result.data.token);
                    localStorage.setItem('isLoggedIn', true);
                    localStorage.setItem('userData', JSON.stringify(result.data))
                    this.setState({redirect: true, isLoading: false});
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({authError: true, isLoading: false});
            });
    };

    componentDidMount() {
        const token = localStorage.getItem('token');
        axios.get(`${this.state.apiUrl}/auth`, { headers: { Authorization: `Bearer ${token}` } })
            .then(result => {
                if (result.data.id) {
                    localStorage.setItem('userData', JSON.stringify(result.data))
                    localStorage.setItem('isLoggedIn', true);
                    this.setState({redirect: true, isLoading: false});
                }
            })
            .catch(error => {
            });
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/dashboard'/>
        }
    };

    render() {
        const isLoading = this.state.isLoading;
        return (
            <div className="container">
                <TitleComponent title="SuperBots Dashboard"></TitleComponent>
                <div style={{textAlign: 'center', marginTop: 50, fontSize: 30}}>SuperBots Dashboard</div>
                <div style={{textAlign: 'center', marginTop: 50, fontSize: 20}}>
                    <select id="inputBackend" onChange={this.handleBackendChange} style={{width: 200}} defaultValue={this.state.apiUrl === Config.apiUrl.dev ? 'dev' : 'prod'}>
                        <option value={'dev'}>Development</option>
                        <option value={'prod'}>Production</option>
                    </select>
                </div>
                <div className="card card-login mx-auto mt-5">
                    <div className="card-header">Login</div>
                    <div className="card-body">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <input className={"form-control " + (this.state.authError ? 'is-invalid' : '')} id="inputEmail" placeholder="Email address" type="text" name="email" onChange={this.handleEmailChange} autoFocus required/>
                                    <label htmlFor="inputEmail">Email address</label>
                                    <div className="invalid-feedback">
                                        Please provide a valid Email.
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <input type="password" className={"form-control " + (this.state.authError ? 'is-invalid' : '')} id="inputPassword" placeholder="******" name="password" onChange={this.handlePwdChange} required/>
                                    <label htmlFor="inputPassword">Password</label>
                                    <div className="invalid-feedback">
                                        Please provide a valid Password.
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" style={{marginRight:5}} value="remember-me"/>Remember Password
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" type="submit" disabled={this.state.isLoading ? true : false}>Login &nbsp;&nbsp;&nbsp;
                                    {isLoading ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                        <span></span>
                                    )}
                                </button>
                            </div>
                        </form>
                        <div className="text-center">
                            <Link className="d-block small mt-3" to={'#'}>Register an Account</Link>
                            <a className="d-block small" href="forgot-password.html">Forgot Password?</a>
                        </div>
                    </div>
                </div>
                {this.renderRedirect()}
            </div>
        );
    }
}


