import React, { Component } from 'react';
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom';
import Header from "../../elements/header";
import Sidebar from "../../elements/sidebar";

import { Config } from '../../configs/env';
import checkNull from '../../utils/helper';
import DateTimePicker from 'react-datetime-picker';


export default class EditPage extends Component {

    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
    }

    state = {
        id: '',
        vault: null,
        redirect: false,
        isLoading: false,
        openAt: null,
        closeAt: null,
        apiUrl: localStorage.getItem('backend') || Config.apiUrl.dev
    };

    componentDidMount() {
        const id = this.props.match.params.id
        axios.get(`${this.state.apiUrl}/vaults/trades-history/item/${id}`, { headers: { Authorization: `Bearer ${this.token}` } })
            .then(response => {
                const tradesData = response.data;
                this.setState({
                    id: id, 
                    tradesData: tradesData, 
                    openAt: tradesData.openAt ? new Date(tradesData.openAt) : null, 
                    closeAt: tradesData.closeAt ? new Date(tradesData.closeAt) : null
                });
                document.getElementById('inputId').value = tradesData.id;
                document.getElementById('inputVaultId').value = tradesData.vault;
                document.getElementById('inputEntryPrice').value = tradesData.entryPrice;
                document.getElementById('inputClosePrice').value = tradesData.closePrice;
                document.getElementById('inputOpenTx').value = tradesData.openTx;
                document.getElementById('inputCloseTx').value = tradesData.closeTx;
            })
            .catch(error => {
                this.setState({ toDashboard: true });
                console.log(error);
            });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        const url = `${this.state.apiUrl}/vaults/trades-history/update/${this.state.id}`;
        const tradesData = {
            vault: document.getElementById('inputVaultId').value,
            openAt: checkNull(this.state.openAt),
            closeAt: checkNull(this.state.closeAt),
            entryPrice: checkNull(document.getElementById('inputEntryPrice').value),
            closePrice: checkNull(document.getElementById('inputClosePrice').value),
            openTx: document.getElementById('inputOpenTx').value,
            closeTx: document.getElementById('inputCloseTx').value
        }
        console.log(tradesData)
        axios.post(url, tradesData , { headers: { Authorization: `Bearer ${this.token}` } })
            .then(result => {
                if (result.data.status) {
                    this.setState({redirect: true, isLoading: false})
                }
            })
            .catch(error => {
                this.setState({ toDashboard: true });
                console.log(error);
            });
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/trades-history' />
        }
    };

    render() {
        const isLoading = this.state.isLoading;
        if (this.state.toDashboard === true) {
            return <Redirect to='/' />
        }
        return (
            <div>
                <Header/>
                <div id="wrapper">
                    <Sidebar></Sidebar>
                    <div id="content-wrapper">
                        <div className="container-fluid">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/trades-history'} >Trades History</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit</li>
                            </ol>
                        </div>
                        <div className="container-fluid">
                            <div className="card mx-auto">
                                <div className="card-header">Trade {this.state.tradesData?.id}</div>
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputId" readOnly className="form-control" placeholder="Enter name" required="required" autoFocus="autofocus" />
                                                <label htmlFor="inputId">Trade Id</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputVaultId" className="form-control" placeholder="Enter name" required="required" autoFocus="autofocus" />
                                                <label htmlFor="inputVaultId">Name</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="col-md-6">
                                                <label style={{marginRight: 10}}>Open At</label>
                                                <DateTimePicker 
                                                    onChange={(date) => this.setState({openAt: date})}
                                                    value={this.state.openAt}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="col-md-6">
                                                <label style={{marginRight: 10}}>Close At</label>
                                                <DateTimePicker 
                                                    onChange={(date) => this.setState({closeAt: date})}
                                                    value={this.state.closeAt}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="number" step={'any'} id="inputEntryPrice" className="form-control" placeholder="Enter Company"/>
                                                <label htmlFor="inputEntryPrice">Entry Price</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="number" step={'any'} id="inputClosePrice" className="form-control" placeholder="Enter Emp ID" />
                                                <label htmlFor="inputClosePrice">Close Price</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputOpenTx" className="form-control" placeholder="Enter Location"/>
                                                <label htmlFor="inputOpenTx">Open Tx</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputCloseTx" className="form-control" placeholder="Enter Emp ID" />
                                                <label htmlFor="inputCloseTx">Close Tx</label>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary btn-block col-md-6" type="submit" disabled={this.state.isLoading ? true : false}>Update Trade History &nbsp;&nbsp;&nbsp;
                                            {isLoading ? (
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </button>
                                    </form>
                                    {this.renderRedirect()}
                                </div>
                            </div>
                        </div>

                        <footer className="sticky-footer">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright © Your Website 2019</span>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}


