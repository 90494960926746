import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import TitleComponent from "../pages/title";
import axios from 'axios';

import { Config } from '../configs/env';


export default class Header extends Component {

    constructor(props) {
        super(props);
        this.handleClickLogout = this.handleClickLogout.bind(this)
    }

    state = {
        toDashboard: false,
        userData: null,
        apiUrl: localStorage.getItem('backend') || Config.apiUrl.dev
    };

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem('userData'))
        this.setState({userData})
    }

    async handleClickLogout(){
        
        const token = localStorage.getItem('token');
        const url = `${this.state.apiUrl}/auth/logout`;
        await axios.post(url, {}, { headers: { Authorization: `Bearer ${token}` } })

        localStorage.setItem('token', null);
        localStorage.setItem('isLoggedIn', false);
        this.setState({ toDashboard: true });
    }

    render() {
        if (this.state.toDashboard === true) {
            return <Redirect to='/' />
        }
        return (
            <nav className="navbar navbar-expand navbar-dark bg-dark static-top">
                <TitleComponent title="SuperBots Dashboard"></TitleComponent>

                <Link to={'/dashboard'} className="navbar-brand mr-1">SuperBots Dashboard</Link>
                <div style={{color: 'white', fontSize:20, backgroundColor: '#888'}} className="rounded px-2 ml-2">{this.state.apiUrl === Config.apiUrl.dev ? 'Dev' : 'Prod'}</div>

                <form className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search for..." aria-label="Search"
                               aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="button">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                    </div>
                </form>

                <ul className="navbar-nav ml-auto ml-md-0">
                    <li className="nav-item dropdown no-arrow">
                        <Link to={'#'} className="nav-link dropdown-toggle" id="userDropdown" role="button"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div style={{display:'inline', marginRight: 5}}>{this.state.userData?.fullname}</div>
                            <i className="fas fa-user-circle fa-fw"></i>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                            <Link to={{ pathname: '/users/edit/' + this.state.userData?.id, search: 'profile' }} className="dropdown-item">Profile</Link>
                            <Link to={'#'} onClick={this.handleClickLogout} className="dropdown-item" data-toggle="modal" data-target="#logoutModal">Logout</Link>
                        </div>
                    </li>
                </ul>
            </nav>
        );
    }
}
