import React, {Component} from 'react';
import Header from "../../elements/header";
import Sidebar from "../../elements/sidebar";
import {Link, Redirect} from "react-router-dom";
import axios from 'axios';

import { Config } from '../../configs/env';
import checkNull from '../../utils/helper';

export default class AddPage extends Component {

    state = {
        redirect: false,
        toDashboard: false,
        isLoading: false,
        apiUrl: localStorage.getItem('backend') || Config.apiUrl.dev
    };

    constructor(props) {
        super(props);
        this.token = localStorage.getItem('token');
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        const url = `${this.state.apiUrl}/vaults/trades-history/create`;
        
        const tradesData = {
            vault: document.getElementById('inputVaultId').value,
            openAt: checkNull(document.getElementById('inputOpenAt').value),
            closeAt: checkNull(document.getElementById('inputCloseAt').value),
            entryPrice: checkNull(document.getElementById('inputEntryPrice').value),
            closePrice: checkNull(document.getElementById('inputClosePrice').value),
            openTx: document.getElementById('inputOpenTx').value,
            closeTx: document.getElementById('inputCloseTx').value
        }

        axios.post(url, tradesData, { headers: { Authorization: `Bearer ${this.token}` } })
            .then(result => {
                if (result.data.status) {
                    this.setState({redirect: true, isLoading: false})
                }
            })
            .catch(error => {
                this.setState({ toDashboard: true });
                console.log(error);
            });
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/trades-history' />
        }
    };

    render() {
        const isLoading = this.state.isLoading;
        if (this.state.toDashboard === true) {
            return <Redirect to='/' />
        }
        return (
            <div>
                <Header/>
                <div id="wrapper">
                    <Sidebar></Sidebar>
                    <div id="content-wrapper">
                        <div className="container-fluid">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/trades-history'} >Trades History</Link>
                                </li>
                                <li className="breadcrumb-item active">Add</li>
                            </ol>
                        </div>
                        <div className="container-fluid">
                            <div className="card mx-auto">
                                <div className="card-header">New Trade History</div>
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputVaultId" className="form-control" placeholder="Enter name" required="required" autoFocus="autofocus" />
                                                <label htmlFor="inputVaultId">Name</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputOpenAt" className="form-control" placeholder="Enter Phone" />
                                                <label htmlFor="inputOpenAt">Open At</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputCloseAt" className="form-control" placeholder="Email address" />
                                                <label htmlFor="inputCloseAt">Close At</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="number" step={'any'} id="inputEntryPrice" className="form-control" placeholder="Enter Company"/>
                                                <label htmlFor="inputEntryPrice">Entry Price</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="number" step={'any'} id="inputClosePrice" className="form-control" placeholder="Enter Emp ID" />
                                                <label htmlFor="inputClosePrice">Close Price</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputOpenTx" className="form-control" placeholder="Enter Location"/>
                                                <label htmlFor="inputOpenTx">Open Tx</label>
                                            </div>
                                        </div>
                                        <div className="form-row my-3">
                                            <div className="form-label-group col-md-6">
                                                <input type="text" id="inputCloseTx" className="form-control" placeholder="Enter Emp ID" />
                                                <label htmlFor="inputCloseTx">Close Tx</label>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary btn-block col-md-6" type="submit" disabled={this.state.isLoading ? true : false}>Add Trade History &nbsp;&nbsp;&nbsp;
                                            {isLoading ? (
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                             ) : (
                                                 <span></span>
                                             )}
                                        </button>
                                    </form>
                                    {this.renderRedirect()}
                                </div>
                            </div>
                        </div>

                        <footer className="sticky-footer">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright © Your Website <div>{(new Date().getFullYear())}</div></span>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}
